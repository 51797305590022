// import { headerSearch } from "../../plugins/ComponentsUi/HeaderSearch/HeaderSearch";
import { detectCloseElement } from "./helper";
/*==================== Header ====================*/
/**
 * @param header
 */
const vw = $(window).width();
export const header = {
	scrollActive: function () {
		let height = $("header").height();
		let windowHeight = $(window).height();
		if ($(window).scrollTop() > height) {
			$("header").addClass("active");
			$(".header-bottom").slideUp();
		} else {
			$("header").removeClass("active");
			$(".header-bottom").slideDown();
		}
		// if ($(window).scrollTop() > windowHeight - height) {
		// 	$('.book-tour-pin').addClass('active');
		// 	$('.book-tour-pin') ? $('.cta-fixed').addClass('hidden') : $('.cta-fixed').removeClass('hidden');
		// } else {
		// 	$('.book-tour-pin').removeClass('active');
		// 	$('.cta-fixed').removeClass('hidden');
		// }
	},
	mobile: function () {
		$(".header-hamburger").on("click", function () {
			$(this).toggleClass("active");
			$(".mobile-wrap").toggleClass("active");
			$(".backdrop-mobile").fadeToggle();
		});
		$(document).on("click", function (e) {
			if (
				!$(e.target).closest($(".mobile-wrap")).length &&
				!$(e.target).closest($(".header-hamburger")).length
			) {
				$(".mobile-wrap").removeClass("active");
				$(".backdrop-mobile").fadeOut();
				$(".header-hamburger").removeClass("active");
			}
		});
		// mapping menu
		const mainMenu = $("header .main-menu").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "prependTo",
			desktopWrapper: ".header-right",
			desktopMethod: "prependTo",
			breakpoint: 1199.98,
		});
		// add arrow dropdown
		if (window.matchMedia("(max-width: 1199.98px)").matches) {
			$(
				'<i class="toggle-menu fa-light fa-chevron-down"></i>'
			).insertAfter("li.menu-item-has-children > a");
			$("li.menu-item-has-children .toggle-menu").on(
				"click",
				function () {
					$(this).toggleClass("active");
					$(this).parent().find("> .sub-menu").slideToggle();
				}
			);
		}
	},
	searchToggle: function () {
		$(".button-search").on("click", function () {
			$(this).toggleClass("active");
			$(".searchbox input").trigger("focus");
			$(".search-wrap").toggleClass("active");
		});
		$(document).on("click", function (e) {
			if (
				!$(e.target).closest($(".search-wrap")).length &&
				!$(e.target).closest($(".button-search")).length
			) {
				$(".button-search").removeClass("active");
				$(".search-wrap").removeClass("active");
				$(".searchbox input").trigger("blur");
			}
		});
	},
	init: function () {
		// headerSearch();
		header.scrollActive();
		header.mobile();
		header.searchToggle();
		const height = $(".header-wrap").height();
		$("body").css("--header-height", height + "px");
		$(window).on("resize", function () {
			const height = $(".header-wrap").height();
			$("body").css("--header-height", height + "px");
		});
		const headerBreadcrumbHeight =
			$(".header-wrap").height() + $(".global-breadcrumb").height();
		$("body").css(
			"--header-breadcrumb-height",
			headerBreadcrumbHeight + "px"
		);
		$(window).on("resize", function () {
			const headerBreadcrumbHeight =
				$(".header-wrap").height() + $(".global-breadcrumb").height();
			$("body").css(
				"--header-breadcrumb-height",
				headerBreadcrumbHeight + "px"
			);
		});
	},
};
document.addEventListener(
	"scroll",
	function (e) {
		header.scrollActive();
	},
	true
);
