export function detectCloseElement(ele, ele2, funcRemove) {
	// close
	$(document).on("click", function (e) {
		console.log();
		if (!$(e.target).closest(ele).length && !$(e.target).hasClass(ele2)) {
			funcRemove();
		}
	});
	// esc
	$(document).keyup(function (e) {
		if (e.key === "Escape") {
			funcRemove();
		}
	});
	// overlay-blur
}

export function buttonToTop() {
	let windowHeight = $(window).height();
	$(document).on("scroll", function () {
		let scrollTop = $(window).scrollTop();
		let documentHeight = $(document).height();
		if (scrollTop + windowHeight > documentHeight - windowHeight) {
			$(".back-to-top").addClass("active");
		} else {
			$(".back-to-top").removeClass("active");
		}
	});
	$(document).on("click", ".back-to-top", function () {
		$("html, body").animate({ scrollTop: 0 }, 100);
	});
}

export function toggleCta() {
	const buttonCTA = $(".toggle-cta");
	buttonCTA.on("click", function () {
		$(this).toggleClass("active").next().slideToggle().parent().toggleClass("active");
	});
}

export function ToggleItem() {
	const nodeParent = $(".wrap-item-toggle");
	const nodeItem = nodeParent.find(".item-toggle");
	const nodeTitle = nodeItem.find(".title");
	nodeTitle.on("click", function () {
		$(this).toggleClass("active").next().slideToggle().closest(".item-toggle").siblings().find(".title").removeClass("active").next().slideUp();
		$(this).parent().toggleClass("active").siblings().removeClass("active");
	});
}

/**
 * parent, children, item, button, initItem
 * @param { parent, children, item, button, initItem} listNode
 */
export function funcExpandContent(listNode) {
	const { parent, children, item, button, initItem, gap = 0 } = listNode;
	if (!$(parent).length) return;
	let itemHeight = $(item).outerHeight();
	let gapCalculate = gap ? Number($(parent).find(children).css("column-gap").slice(0, -2)) * gap : 0;
	let initHeight = itemHeight * initItem + gapCalculate;
	let originalHeight = $(parent).find(children).outerHeight();
	if (originalHeight < initHeight) {
		$(button).remove();
	} else {
		$(parent).css("height", initHeight);
	}
	$(button).on("click", function () {
		if ($(this).hasClass("expand")) {
			$(parent).css("height", initHeight);
			$(this).find("span").text("Xem thêm");
		} else {
			$(parent).css("height", originalHeight);
			// setTimeout(() => {
			// 	$(parent).css("height", "auto");
			// }, 1000);
			$(this).find("span").text("Rút gọn");
		}
		$(this).toggleClass("expand");
	});
}

export function clickScrollToDiv(nodeEle, heightSpacing = () => {}) {
	$(nodeEle).on("click", function (event) {
		let height = 0;
		$(this).addClass("active").siblings().removeClass("active");
		if (heightSpacing) {
			height = heightSpacing();
		} else {
			height = 0;
		}
		if (this.hash !== "") {
			event.preventDefault();
			var hash = this.hash;
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - height,
				},
				800
			);
		}
	});
}

export function appendCaptchaASP() {
	if (!$("#ctl00_mainContent_ctl01_pnlFormWizard").length) return;
	// Select the div element you want to observe
	const myDiv = document.querySelector("#ctl00_mainContent_ctl01_pnlFormWizard");
	// Create a new MutationObserver object
	const observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			console.log("Run");
			appendCaptcha();
		});
	});
	// Configure the observer to listen for changes to the "class" attribute
	const config = { attributes: true, characterData: true, childList: true };
	// Start observing the target div element
	observer.observe(myDiv, config);
	function appendCaptcha() {
		$(".form-group.frm-captcha").appendTo(".wrap-form-submit");
		$(".form-group.frm-btnwrap").appendTo(".wrap-form-submit");
	}
	appendCaptcha();
}

export function toggleFilterMobile() {
	// Generalized toggle function for filters
	function setupToggle(buttonClass, boxClass, backdropClass, closeClass) {
		// Toggle box and backdrop on button click
		$(buttonClass).on('click', function () {
			$(boxClass).toggleClass('active');
			$(backdropClass).fadeToggle();
		});
		// Close box and backdrop on close button click
		$(closeClass).on('click', function () {
			$(boxClass).removeClass('active');
			$(backdropClass).fadeOut();
		});
		// Close box and backdrop if clicked outside of the box or button
		$(document).on('click', function (e) {
			if (!$(e.target).closest(buttonClass).length && !$(e.target).closest(closeClass).length && !$(e.target).closest(boxClass).length) {
				$(boxClass).removeClass('active');
				$(backdropClass).fadeOut();
			}
		});
	}

	// Set up toggles for each type
	setupToggle('.button-filter-mobile', '.box-filter', '.backdrop-filter', '.close-filter');
}

export function loadMoreItem() {
	$(".load-more-wrap").each(function () {
		let $this = $(this);
		let loadMore = $this.data("load-more");
		let loadMoreItem = $this.find(".load-more-item");
		let loadMoreButton = $this.find(".load-more-button");
		$(loadMoreItem).slice(0, loadMore).show();

		$(loadMoreButton).on("click", function (e) {
			e.preventDefault();
			let loadMoreItemHidden = $this.find(".load-more-item:hidden");
			$(loadMoreItemHidden).slice(0, loadMore).slideDown();
			let loadMoreItemHiddenLength = $this.find(
				".load-more-item:hidden"
			).length;
			if ($(loadMoreItemHiddenLength).length == 0) {
				$(loadMoreButton).fadeOut("slow");
			}
		});
	});
}
