// import AOS from "aos";
import lozad from "lozad";
import { header } from "./header";
import swiperInit from "./swiper";
import fancyboxInit from "./fancybox";
import { buttonToTop, toggleCta, loadMoreItem } from "./helper";
$(function () {
	header.init();
	swiperInit();
	lozadInit();
	fancyboxInit();
	buttonToTop();
	toggleCta();
	loadMoreItem();
	countUpInit();
	if ($('#site-sticky-nav').length) {
		var elm = document.querySelector('#site-sticky-nav');
		var ms = new MenuSpy(elm, {
			activeClass: 'active',
			threshold: $('header').outerHeight(),
		});
	}
});

/*==================== Aos Init ====================*/
// AOS.init({
// 	offset: 100,
// });
/*==================== Lazyload JS ====================*/

function lozadInit() {
	console.log("lozadInit");
	const observer = lozad(); // lazy loads elements with default selector as '.lozad'
	const backgroundObserver = lozad('.lozad-bg', {
		threshold: 0.1,
	});
	observer.observe();
	backgroundObserver.observe();
}

function countUpInit() {
	$('.section-count-up').each(function (indexSection) {
		$('.count-up').each(function (index) {
			$(this).attr('id', `countUp-${indexSection}-${index}`)
			const endVal = $(this).data('count');
			const decimal = $(this).data('decimal');
			const options = {
				separator: ',',
				decimal: decimal ? decimal : '.',
				decimalPlaces: decimal ? 1 : 0,
				enableScrollSpy: true,
				scrollSpyDelay: 1000,
				scrollSpyOnce: true,
				useEasing: true,
				useGrouping: true,
			};

			const countUp = new CountUp(`countUp-${indexSection}-${index}`, endVal, options);

			// if (!countUp.error) {
			// 	countUp.start();
			// } else {
			// 	console.error(countUp.error);
			// }
			var waypoint = new Waypoint({
				element: document.getElementsByClassName('section-count-up')[indexSection],
				handler: function (direction) {

					// if (direction == "up") {
					// 	countUp.reset();
					// } else {
					countUp.start();
					// }

				},
				offset: '50%'
			});

			// countUp.handleScroll();
		});
	});
}
window.lozadInitNew = lozadInit;
window.loadMoreItemNew = loadMoreItem;
