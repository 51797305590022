import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Thumbs, Scrollbar } from 'swiper/modules';
/**
 * @param swiperInit
 */
export default function swiperInit() {
	// Make handleVideoEnd available globally
	window.handleVideoEnd = function(videoElement) {
		const swiperInstance = homeBannerSwiper;
		if (swiperInstance) {
			swiperInstance.slideNext();
		}
	};

	const homeBannerSwiper = new Swiper(".banner-slider .swiper", {
		modules: [Autoplay, Navigation, Pagination],
		loop: true,
		speed: 1000,
		navigation: {
			nextEl: ".banner-slider .button-next",
			prevEl: ".banner-slider .button-prev",
		},
		pagination: {
			el: ".banner-slider .swiper-pagination",
			clickable: true,
		},
	});

	const pageBannerSwiper = new Swiper(".page-banner-main .swiper", {
		modules: [Autoplay, Navigation, Pagination],
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		// effect: "fade",
		speed: 1000,
	});
	const productSwiperThumbs = new Swiper(".product-dt-slider .product-thumbs .swiper", {
		spaceBetween: 10,
		slidesPerView: 3,
		// freeMode: true,
		watchSlidesProgress: true,
		breakpoints: {
			1024: {
				slidesPerView: 5,
			},
			1200: {
				direction: "vertical",
				spaceBetween: 28,
				slidesPerView: 3,
			},
		},
	});
	const productSwiperTop = new Swiper(".product-dt-slider .product-top .swiper", {
		modules: [Navigation, Thumbs],
		spaceBetween: 10,
		navigation: {
			nextEl: ".product-dt-slider .button-next",
			prevEl: ".product-dt-slider .button-prev",
		},
		thumbs: {
			swiper: productSwiperThumbs,
		},
	});
	const home6Slider = new Swiper(`.home-6-slider .swiper`, {
		modules: [Autoplay, Navigation, Pagination],
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		// rewind: true,
		speed: 1000,
		spaceBetween: 16,
		slidesPerView: 1,
		navigation: {
			prevEl: `.home-6-slider .button-prev`,
			nextEl: `.home-6-slider .button-next`,
		},
		pagination: {
			el: `.home-6-slider .swiper-pagination`,
			clickable: true,
			dynamicBullets: true,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 32,
			},
			1400: {
				slidesPerView: 4,
				spaceBetween: 40,
			},
		},
	});
	$('.one-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-one-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-one-${index}`);
		$(this).find('.button-prev').addClass(`prev-one-${index}`);
		$(this).find('.button-next').addClass(`next-one-${index}`);
		const oneSlider = new Swiper(`.swiper-one-${index}`, {
			modules: [Autoplay, Navigation, Pagination],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			// rewind: true,
			speed: 1000,
			spaceBetween: 16,
			slidesPerView: 1,
			navigation: {
				prevEl: `.one-slider .prev-one-${index}`,
				nextEl: `.one-slider .next-one-${index}`,
			},
			pagination: {
				el: `.one-slider .pagination-one-${index}`,
				clickable: true,
				// dynamicBullets: true,
			},
			breakpoints: {
				768: {
					spaceBetween: 32,
				},
				1400: {
					spaceBetween: 40,
				},
			},
		});
	});
	$('.two-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-two-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-two-${index}`);
		$(this).find('.button-prev').addClass(`prev-two-${index}`);
		$(this).find('.button-next').addClass(`next-two-${index}`);
		const twoSlider = new Swiper(`.swiper-two-${index}`, {
			modules: [Autoplay, Navigation, Pagination],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			// rewind: true,
			speed: 1000,
			spaceBetween: 16,
			slidesPerView: 1,
			navigation: {
				prevEl: `.two-slider .prev-two-${index}`,
				nextEl: `.two-slider .next-two-${index}`,
			},
			pagination: {
				el: `.two-slider .pagination-two-${index}`,
				clickable: true,
				dynamicBullets: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1200: {
					slidesPerView: 2,
					spaceBetween: 32,
				},
				1400: {
					slidesPerView: 2,
					spaceBetween: 40,
				},
			},
		});
	});
	$('.three-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-three-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-three-${index}`);
		$(this).find('.button-prev').addClass(`prev-three-${index}`);
		$(this).find('.button-next').addClass(`next-three-${index}`);
		const threeSlider = new Swiper(`.swiper-three-${index}`, {
			modules: [Autoplay, Navigation, Pagination],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			// rewind: true,
			speed: 1000,
			spaceBetween: 16,
			slidesPerView: 1,
			navigation: {
				prevEl: `.three-slider .prev-three-${index}`,
				nextEl: `.three-slider .next-three-${index}`,
			},
			pagination: {
				el: `.three-slider .pagination-three-${index}`,
				clickable: true,
				dynamicBullets: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
				1200: {
					slidesPerView: 3,
					spaceBetween: 32,
				},
				1400: {
					slidesPerView: 3,
					spaceBetween: 40,
				},
			},
		});
	});
	$('.four-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-four-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-four-${index}`);
		$(this).find('.swiper-scrollbar').addClass(`scrollbar-four-${index}`);
		$(this).find('.button-prev').addClass(`prev-four-${index}`);
		$(this).find('.button-next').addClass(`next-four-${index}`);
		const fourSlider = new Swiper(`.swiper-four-${index}`, {
			modules: [Autoplay, Navigation, Pagination, Scrollbar],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			// rewind: true,
			speed: 1000,
			spaceBetween: 16,
			slidesPerView: 1,
			navigation: {
				prevEl: `.four-slider .prev-four-${index}`,
				nextEl: `.four-slider .next-four-${index}`,
			},
			pagination: {
				el: `.four-slider .pagination-four-${index}`,
				clickable: true,
				dynamicBullets: true,
			},
			scrollbar: {
				el: `.four-slider .scrollbar-four-${index}`,
				draggable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
				1400: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
		});
	});
	$('.six-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-six-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-six-${index}`);
		$(this).find('.swiper-scrollbar').addClass(`scrollbar-six-${index}`);
		$(this).find('.button-prev').addClass(`prev-six-${index}`);
		$(this).find('.button-next').addClass(`next-six-${index}`);
		const sixSlider = new Swiper(`.swiper-six-${index}`, {
			modules: [Autoplay, Navigation, Pagination, Scrollbar],
			loop: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			// rewind: true,
			speed: 1000,
			spaceBetween: 16,
			slidesPerView: 2,
			navigation: {
				prevEl: `.six-slider .prev-six-${index}`,
				nextEl: `.six-slider .next-six-${index}`,
			},
			pagination: {
				el: `.six-slider .pagination-six-${index}`,
				clickable: true,
				dynamicBullets: true,
			},
			scrollbar: {
				el: `.six-slider .scrollbar-six-${index}`,
				draggable: true,
			},
			breakpoints: {
				576: {
					slidesPerView: 3,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 16,
				},
				1024: {
					slidesPerView: 5,
					spaceBetween: 16,
				},
				1200: {
					slidesPerView: 6,
					spaceBetween: 20,
				},
				1400: {
					slidesPerView: 6,
					spaceBetween: 40,
				},
			},
		});
	});
	$('.brand-slider').each(function (index) {
		$(this).find('.swiper').addClass(`swiper-brand-${index}`);
		$(this).find('.swiper-pagination').addClass(`pagination-brand-${index}`);
		$(this).find('.button-prev').addClass(`prev-brand-${index}`);
		$(this).find('.button-next').addClass(`next-brand-${index}`);
		const brandSlider = new Swiper(`.swiper-brand-${index}`, {
			modules: [Autoplay, Navigation, Pagination],
			loop: true,
			autoplay: {
				delay: 1,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			speed: 3000,
			spaceBetween: 16,
			slidesPerView: 'auto',
			navigation: {
				prevEl: `.brand-slider .prev-brand-${index}`,
				nextEl: `.brand-slider .next-brand-${index}`,
			},
			pagination: {
				el: `.brand-slider .pagination-brand-${index}`,
				clickable: true,
			},
			breakpoints: {
				1200: {
					spaceBetween: 20,
				},
				1400: {
					spaceBetween: 32,
				},
			},
		});
	});
	const about2Slider = new Swiper(`.about-2-slider .swiper`, {
		modules: [Autoplay, Navigation, Pagination],
		loop: true,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		spaceBetween: 0,
		slidesPerView: 1,
		breakpoints: {
			576: {
				slidesPerView: 2,
				// spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				// spaceBetween: 16,
				loop: false,
				autoplay: false,
			},
			1200: {
				slidesPerView: 3,
				// spaceBetween: 32,
				loop: false,
				autoplay: false,
			},
			1400: {
				slidesPerView: 3,
				// spaceBetween: 40,
				loop: false,
				autoplay: false,
			},
		},
	});
}
