import { Fancybox } from "@fancyapps/ui";
/**
 * @param fancyboxInit
 */

export default function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		// mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	$(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}
